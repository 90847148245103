@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

#__next {
  height: 100vh;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul {
  margin: 0;
  padding-left: 1rem;
}
li {
  color: inherit;
}

* {
  box-sizing: border-box;
}

:root {
  --primary-color: #691A6B;
  --primary-color-light: #e6e6e6;
  --primary-color-burn: #cdcdd6;
  --secondary-color: #7f7f7f;
  --text-color: #2A2A2A;
  --background-color: rgb(242, 242, 242);
}
