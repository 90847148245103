.root {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 45, 45, 0.6);
    z-index: 100;
    display: grid;
    place-items: center;
}

.modal {
    max-height: 90vh;
    max-width: 90vw;
    background: var(--modal-bg-color, var(--primary-color));
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 1rem;
}

.modal:focus {
    outline: none;
}

.scrollContainer {
    padding: 1rem;
    max-height: 100%;
    height: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.scrollContainer::-webkit-scrollbar {
    display: none;
}

.scrollContainer > * > h4 {
    color: white;
}

.close {
    position: absolute;
    z-index: 1000;
    right: -0.7rem;
    top: -0.7rem;
    cursor: pointer;
    color: var(--primary-color);
    background: white;
    border-radius: 100%;
}
